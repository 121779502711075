<template>
  <div id="app">
    <NavbarComponent2/> <!-- This stays outside and is always displayed -->
    <router-view/> <!-- This will change based on the route -->
  </div>
</template>

<script>
import NavbarComponent2 from './components/NavbarComponent2.vue' // Keep the Navbar import

export default {
  name: 'App',
  components: {
    NavbarComponent2, // Only NavbarComponent needs to be registered here
  }
}
</script>

<style>

:root {
  --background-color: #1f1f1f;
  --font-color: rgb(255, 255, 255);
  --secondary-background:rgb(0, 0, 0);
}


@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
#app {
  font-family: Quicksand, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

body {
    padding:0;
    margin:0;
    font-family: "Quicksand", sans-serif;
 /*    background: url('./assets/yohann-libot-atBSCnuYyRI-unsplash.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; */
}


.fade-in {
  animation: fadeIn 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



</style>
