<template>
  <header id="navbar" :class="{ transparent: isTransparent }" @click.self="closeNavbar">
    <div class="navbar-sub">

     <button class="left-logo" :class="{ isScrolled, hiddenLogo: shouldHideLogo }">
          <router-link class="logo-container" to="/">
            <h1 class="logo">lumina<span>visions</span></h1>
          </router-link>
        </button>

      <nav :class="{ 'responsive_nav': showNavbar }">
        <button @click.stop="toggleNavbar">
          <router-link to="/" class="nav-link">home</router-link>
        </button>
        <button @click.stop="toggleNavbar">
          <router-link to="/references" class="nav-link">references</router-link>
        </button>
        <button @click.stop="toggleNavbar">
          <router-link to="/contact" class="nav-link">contact</router-link>
        </button>
      </nav>

      <button class="nav-btn" @click.stop="toggleNavbar" v-show="windowWidth <= 1024">{{ showNavbar ? 'close' : 'menu' }}</button>
    </div>
  </header>
</template>


  
  
  <script>
import { defineComponent } from 'vue';


export default defineComponent({
  name: "NavbarComponent2",
  data() {
    return {
      showNavbar: false,
      isTransparent: false,
      isScrolled: false,
      windowWidth: window.innerWidth,
      scrollTop: 0
    };
  },
  computed: {
    shouldHideLogo() {
      // Check if current route is home and scroll position is less than 50vh
      const path = this.$route.path;
      return (path === '/' || path === '/home') && this.scrollTop < window.innerHeight * 0.5;
    }
  },
  methods: {
    handleScroll() {
      this.isTransparent = window.scrollY > 10;
      this.isScrolled = window.scrollY > 10;
      this.scrollTop = window.scrollY;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    closeNavbar() {
      if (this.showNavbar) {
        this.showNavbar = false;
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }
});
</script>

<style scoped>

.hiddenLogo .logo {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.logo-container .logo {
  transition: opacity 0.5s ease-in-out;
}

.nav-link {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 8px;
  text-decoration: none;
  font-weight: 700;
  text-transform: lowercase;
  transition: color 0.3s;
}
.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}
.nav-link:hover::after, .nav-link.router-link-active::after {
  width: 100%;
}
.nav-link.router-link-active {
  font-weight: bold;
}

.navbar-sub {
  max-width: 1550px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin: auto;
}

header {
  color: white;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  font-size: 17px;
  padding: 12px 12px;
  width: 100%;
  
}

@media (max-width: 1024px) {
  .nav-btn {
    display: inline-block;
    visibility: visible;
    opacity: 1;
    font-size: 1.6rem;
    background-color: black;
    padding: 6px 12px;
    z-index: 101; /* Higher than nav overlay */
    width:100px;
    transition:0.3s ease-in-out;
    outline:none;
  }

  .nav-btn:hover{
    display: inline-block;
    visibility: visible;
    opacity: 1;
    font-size: 1.6rem;
    background-color: var(--background-color);
    padding: 6px 12px;
    z-index: 101;
    width:100px;
    transition:0.3s ease-in-out;
  }

  header nav {
    position: fixed;
    top: 12px;
    right: -100%; /* Start from off-screen */
    height: 0;
    width:100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    background: var(--background-color);
    transition: right 0.3s ease-out, width 0.3s ease-out 0.3s, height 0.3s ease-out 0.6s; /* Delays added */
    overflow: hidden;
    
  }

  header .responsive_nav {
    right: 12px;
    max-width: 90vw;
    min-width:60vw;
    height: 60vh; /* Animate to full size */
    transition: right 0.3s ease-out, width 0.3s ease-out 0.3s, height 0.3s ease-out 0.6s; /* Delays adjusted */
    
  }


.responsive_nav {

  padding-top:60px;
  border-left: 32px solid black;

}

.responsive_nav button a{

font-size:calc(32px + 3vw);
font-weight:200 !important;

}

}
</style>

  