<template>

    <footer>

        <div>
            <div class="footer-sitemap">
                    <ul>
                        <li><router-link to="/home" custom v-slot="{ navigate }">
        <button @click="navigate">home</button>
      </router-link></li>
                        <li>  <router-link to="/references" custom v-slot="{ navigate }">
        <button @click="navigate">references</button>
      </router-link></li>
                        <li><router-link to="/contact" custom v-slot="{ navigate }">
        <button @click="navigate">contact</button>
      </router-link></li>
                        <li><router-link to="/impressum" custom v-slot="{ navigate }">
        <button @click="navigate">impressum</button>
      </router-link></li>
                        <li><router-link to="/datapolicy" custom v-slot="{ navigate }">
        <button @click="navigate">data policy</button>
      </router-link></li>
                    </ul>
            </div>
        </div>

        <div>

            <div class="footer-contact">

                <ul>
                        <li><a href="mail:info@luminavisions.com" target="_blank" rel="noreferrer noopener">info@luminavisions.com</a></li>
                        <li><a href="tel:+4988745894684" target="_blank" rel="noreferrer noopener">+4988745894684</a></li>
                        <li><a href="maps.google.com" target="_blank" rel="noreferrer noopener">40 E 7th St, New York, NY 10003, USA</a></li>
                    </ul>

            </div>

        </div>

        <div>
            <div class="footer-icons">
            <a href="https://www.facebook.com/" target="_blank" rel="noreferrer noopener"><font-awesome-icon :icon="['fab', 'facebook']" class="icon" /></a>
            <a href="https://www.twitter.com/" target="_blank" rel="noreferrer noopener"><font-awesome-icon :icon="['fab', 'twitter']" class="icon" /></a>
            <a href="https://www.instagram.com/" target="_blank" rel="noreferrer noopener"><font-awesome-icon :icon="['fab', 'instagram']" class="icon" /></a>
            <a href="https://www.linkedin.com" target="_blank" rel="noreferrer noopener"><font-awesome-icon :icon="['fab', 'linkedin']"  class="icon" /></a>
        </div>
        </div>

    </footer>

    <section class="copyright">Copyright © 2024 luminavisions ®</section>

    </template>
    
    <script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
    
    // Add icons to the library
    library.add(faFacebook, faTwitter, faInstagram, faLinkedin);
    
    export default {
      name: 'FooterComponent',
      components: {
        FontAwesomeIcon
      }
    }
    </script>
    
    
<style scoped>

@media only screen and (max-width: 1024px) { 

    footer {
    display: flex;
    flex-direction:column;
    }

    footer div {
    width:100% !important;
        margin:auto !important;
        padding:24px !important;
        display:flex;
        justify-content:center;
        text-align:center;
}

 }

    footer {
        
        width:100%;
        min-height:300px;
        background-color:black;
        color:white;
    display: flex;
    justify-content: center;
    align-items: center;

    }

footer div {

    width:33%;

}
.footer-sitemap, 
.footer-contact, 
.footer-icons {

    margin:auto;

}

    .icon {
        font-size:36px;
        padding:6px;
        transition:0.3s ease-in-out;
}
.icon:hover {
        color:grey;
        transition:0.3s ease-in-out;
}

.footer-contact ul li {
        transition:0.3s ease-in-out;
}

.footer-contact ul li:hover {
    color:grey;
        transition:0.3s ease-in-out;

}

.footer-sitemap ul li:hover {
    color:grey;
        transition:0.3s ease-in-out;
}
.footer-sitemap ul li {
        transition:0.3s ease-in-out;
}

.copyright {

    height:50px;
    width:100%;
    background-color:var(--background-color);
    color:white;
    
    padding:12px;
}

</style>