<template>
    <div class="parallax-container" :style="parallaxStyle">
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ParallaxComponent',
    props: {
      imageUrl: {
        type: String,
        required: true
      },
      height: {
        type: String,
        default: '500px'
      },
      parallaxSpeed: {
        type: Number,
        default: 0.5
      }
    },
    data() {
      return {
        scrollY: 0
      };
    },

    computed: {
  parallaxStyle() {
    const offset = this.scrollY * this.parallaxSpeed;
    return {
      height: this.height,
      backgroundImage: `url(${this.imageUrl})`,
      backgroundSize: 'auto 120%', // Try changing this to "auto 110%" to make the image taller
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'scroll',
      backgroundPosition: `50% ${-offset}px`
    };
  }
},



    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
  handleScroll() {
    this.scrollY = window.scrollY || document.documentElement.scrollTop;
    // This ensures the component knows it needs to update
    this.$forceUpdate();
  }
},

  };
  </script>
  
  <style scoped>
  .parallax-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  </style>
  