<template>
  <section class="album-details-page">
    <div class="album-details" v-if="album">
      <div class="album-detail-textbox">
        <h1>{{ album.title }}</h1>
        <RichTextRenderer :richText="album.longDescription" />
      </div>
      <div class="album-images" ref="imageContainer">
        <div v-for="(image, index) in album.images" :key="index" class="image-container">
          <div class="image-wrap">
            <img :src="image.url" :alt="`Image ${index+1} of album - ${image.description}`" />
            <p>{{ image.description }}</p>
            

          </div>
        </div>
      </div>
    </div>
    <div v-else>

      <p>Loading album details...</p>
      
    </div>
  </section>
</template>



        
<script>
import axios from 'axios';
import RichTextRenderer from './RichTextRenderer.vue';

export default {
  name: 'ReferencesDetails',
  props: {
    slug: String
  },
  components: {
    RichTextRenderer
  },
  
  data() {
    return {
      album: null
    };
  },
  
  created() {
    this.fetchAlbumDetails();
  },
 
  methods: {
    fetchAlbumDetails() {
      axios.get(`https://devcraft.hu/proxy-server/api/albums/${this.slug}`)
        .then(response => {
          this.album = response.data;
        })
        .catch(error => console.error('Error fetching album details:', error));
    }
  }
}
</script>
 
        
<style scoped>

@media only screen and (max-width: 1024px) { 

  .album-details-page {
  background-color: #ad7777;
  border-left: 36px solid rgb(255, 255, 255);
  height: 100vh;
  width: 100vw;
  overflow: scroll !important;
  display: flex; 
  
}

.album-details {
  display: flex;
  height: max-content !important; 
  width: 100%;
  flex-direction:column;
}


.album-detail-textbox {
  width: 100%  !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  position: static !important;
  margin-top:90px;
}

.album-detail-textbox h1 {
  font-size: 32px !important;
}

.album-detail-textbox p {
  font-size: 14px !important;

}

.image-wrap img {
  margin-top:0px !important;
  max-width: 100% !important;
  max-height: 90vh !important;
}
.album-images {
  width: 100% !important;
  overflow-y: initial !important; 
  max-height: 100vh;  /* Make sure the height is defined */
  -ms-overflow-style: none;  /* Removes scrollbar in IE/Edge */
  scrollbar-width: none;  /* Removes scrollbar in Firefox */
  scroll-snap-type: proximity !important; /* Enables scroll snapping */
  scroll-behavior: smooth;
}

.album-details-page {
  background-color: #ad7777;
  border-left: 6px solid rgb(255, 255, 255) !important;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex; 
}

.image-wrap p {
  width: 90%; /* Matches the image width */
  text-align: left;
  color: white;
  font-weight: 300;
  font-size: 12px !important;
  padding-top:6px !important;
  padding-left:6px;
}

}


.album-details-page {
  background-color: #ad7777;
  border-left: 36px solid rgb(255, 255, 255);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex; 
}

.album-details {
  display: flex;
  height: 100vh; 
  width: 100%;
}

.album-detail-textbox {
  width: 20%;
  padding-left: 24px;
  position: sticky;
  margin-top:90px;
}

.album-detail-textbox h1 {
  font-weight: 300;
  font-size: 48px; /* Adjusted for consistency */
  color: var(--font-color);
  text-transform: uppercase;
}

.album-detail-textbox p {
  font-weight: 300;
  font-size: 20px; /* Adjusted for consistency */
  color: var(--font-color);
  
}

.images-container {
  margin: auto;
  width: 100%;
}

.album-images {
  width: 80%;
  overflow-y: auto; 
  max-height: 100vh;  /* Make sure the height is defined */
  -ms-overflow-style: none;  /* Removes scrollbar in IE/Edge */
  scrollbar-width: none;  /* Removes scrollbar in Firefox */
  scroll-snap-type: y mandatory; /* Enables scroll snapping */
  scroll-behavior: smooth;
}

.album-images::-webkit-scrollbar {
  display: none; /* Removes scrollbar in WebKit browsers */
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Space between each image */
  scroll-snap-align: start; /* Snaps the top of each image to the scroll container's top */
}


.image-wrap {
  display: block;
  text-align: left; /* Centers the image and paragraph */
}

.image-wrap img {
  margin-top:90px;
  max-width: 90%; 
  max-height: 85vh; 
}

.image-wrap p {
  width: 90%; /* Matches the image width */
  text-align: left;
  color: white;
  font-weight: 300;
  font-size: 18px;
  padding-top:12px;
}
</style>


        