<template>
    <div class="reference-cards">
      <div class="reference-card" v-for="(album, index) in albums" :key="index">
        <router-link :to="{ name: 'ReferencesDetails', params: { slug: album.albumSlug } }">
          <img :src="album.coverUrl" alt="Cover Image" />
        </router-link>
          <div class="reference-card-text">
            <router-link :to="{ name: 'ReferencesDetails', params: { slug: album.albumSlug } }"><h1>{{ album.title }}</h1></router-link>
            <p>{{ album.shortDescription }}</p>
          </div>
        
      </div>
    </div>
  </template>
  
  
        
<script>

import ReferencesDetails from './ReferencesDetails.vue'
import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'ReferencesShowcaseComponent',
  components: ReferencesDetails,
  data() {
    return {
      albums: []  
    };
  },
  created() {
    this.fetchAlbums(); 
  },
  methods: {
    fetchAlbums() {
      axios.get('https://devcraft.hu/proxy-server/api/albums/')
        .then(response => {
          this.albums = response.data.map(item => ({
            title: item.fields.title,
            shortDescription: item.fields.shortDescription,
            albumSlug: item.fields.albumSlug,
            coverUrl: item.fields.cover.fields.file.url
          }));
        })
        .catch(error => console.error('Error fetching albums:', error));
    }
  }
})
</script>

        
        
<style scoped>

img {
transition:0.3s ease-in-out;
}

img:hover {
  filter: grayscale(60%);
  transition:0.3s ease-in-out;
}

@media only screen and (max-width: 1024px) {

  .reference-card {
    margin:24px auto 24px auto !important;   
  padding: 12px; 
  color:var(--font-color); 
  flex: 0 0 auto; 
  width:90vw !important;
  height:500px;
  border-right:1px solid rgba(128, 128, 128, 0.252);
  padding-top:0px;
  transition: 0.6s ease-in-out;
}
.reference-cards {
    min-height:100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;  /* Adjust this as needed for alignment */
    padding:12px !important;
}

.reference-card:first-child {
  margin: 24px auto 24px auto !important;
  padding: 12px !important;
  padding-top: 0 !important;
  color: var(--font-color);
  flex: auto !important;
  width:90vw !important;
  height: 500px !important;
  border-right: 1px solid rgba(128, 128, 128, 0.252);
  padding-top: 0px !important;
  transition: 0.6s ease-in-out;

  display: block !important;
  align-items: center;
  justify-content: space-between; 

}

.reference-card:first-child  img {
    width: 100% !important; 
    height: 320px !important; 
    overflow:hidden !important;
    object-fit:cover !important;
    padding-top:0px !important; 

}

.reference-card  img {
    width: 100% !important;  
    height: 320px !important;  
    overflow:hidden !important; 
    object-fit:cover !important; 
    padding-top:0px !important; 
}


.reference-card:first-child  a {
  width: 100% !important; 
    height: auto; 
   overflow:hidden;
}
.reference-card a{
  width: 100% !important; 
    height: auto; 
   overflow:hidden;
}


.reference-card:last-child  {
  border-right:1px solid rgba(128, 128, 128, 0.252) !important;
}

.reference-card:first-child .reference-card-text {
  padding: 10px !important;
  height:auto !important;
  width:100% !important;
}

.reference-card-text {
  padding: 10px !important;
  height:auto !important;
  width:100% !important;
}
.reference-card:first-child .reference-card-text h1 {

  font-weight:400 !important;
font-size:24px !important;
color:var(--font-color);
padding-left:0px !important;
}
.reference-card:first-child .reference-card-text  p {
  font-weight:300;
font-size:14px;
color:var(--font-color);
padding-left:0px !important;
}



 }



.reference-cards {
    min-height:100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;  /* Adjust this as needed for alignment */
    padding:24px;
}

.reference-card {
  margin:48px auto 48px auto;  
  padding: 12px; 
  color:var(--font-color); 
  flex: 0 0 auto; 
  width:450px;
  height:500px;
  border-right:1px solid rgba(128, 128, 128, 0.252);
  padding-top:0px;
  transition: 0.6s ease-in-out;
}



.reference-card:last-child  {
  border-right:none;
}

.reference-card:first-child {
  padding: 12px;
  height: 650px;
  flex: 1 0 100%; 
  display: flex;
  align-items: center;
  justify-content: space-between;  
}

.reference-card:first-child  img {
  width: 100% !important;  
  height: 100%; 
  overflow:hidden;
  object-fit:cover;
}

.reference-card:first-child  a {
  width: 75% !important;  
  max-height: 100%; 
}
.reference-card a{
    width: 420px; 
    height: auto; 
   overflow:hidden;
}
.reference-card  img {
    width: 100%; 
    height: 320px; 
    overflow:hidden;
    object-fit:cover;
    padding-top:0px;
}




.reference-card-text {
  padding: 10px;
  height:100%;
}




.reference-card:first-child .reference-card-text {
  width: 50%; 
}

.reference-card:first-child .reference-card-text h1 {

    font-weight:400;
    font-size:32px;
    color:var(--font-color);
    padding-left:24px;
}
.reference-card:first-child .reference-card-text  p {
    font-weight:300;
    font-size:16px;
    color:var(--font-color);
    padding-left:24px;

}
.reference-card-text h1 {

font-weight:400;
font-size:24px;
color:var(--font-color);

}
.reference-card-text p {
font-weight:300;
font-size:14px;
color:var(--font-color);
}







</style>


        