<template>

<section class="about-section">

<Carousel v-bind="settings" :breakpoints="breakpoints">
    <Slide v-for="slide in 1" :key="slide">
        <div class="carousel__item">
            <div class="about-card">
            <div class="about-card-header">
                <p>Timeless Photography</p>
                <p>Memorable Moments</p>
                <p>Professional Photography</p>
            </div>
            <div class="about-card-text">Welcome to LuminaVisions, where every photograph is a window to a memorable story. Let us bring your special moments into everlasting light with our professional touch.</div>
            <div class="about-card-img">
                <img src="../assets/memory.jpg" />
            </div>
            <div class="about-card-logo"><h1>lumina<span>visions</span></h1></div>
        </div>
    </div>
    </Slide>
    <Slide v-for="slide in 1" :key="slide">
        <div class="carousel__item">

        <div class="about-card">
            <div class="about-card-header">
                <p>Art Of Photography</p>
                <p>Elegant Captures</p>
                <p>Visual Storytelling</p>
                </div>
            <div class="about-card-text">Discover the art of photography with LuminaVisions, as we capture the essence of each moment with elegance and precision. Our passion is to create images that not only reflect your life but also enhance it.</div>
            <div class="about-card-img">
                <img src="../assets/art.jpg" />
            </div>
            <div class="about-card-logo"><h1>lumina<span>visions</span></h1></div>
        </div>
    </div>
    </Slide>
<Slide v-for="slide in 1" :key="slide">
        <div class="carousel__item">

        <div class="about-card">
            <div class="about-card-header">
                <p>Timeless Masterpieces</p>
                <p>Visual Journey</p>
                <p>Memory Canvas</p>
            </div>
            <div class="about-card-text">At LuminaVisions, we specialize in transforming your fleeting moments into timeless masterpieces. Join us on a visual journey where your memories become our canvas.</div>
            <div class="about-card-img">
                <img src="../assets/moments.jpg" />
            </div>
            <div class="about-card-logo"><h1>lumina<span>visions</span></h1></div>
        </div>
    </div>
    </Slide>
<Slide v-for="slide in 1" :key="slide">
        <div class="carousel__item">

        <div class="about-card">
            <div class="about-card-header">
                <p>Magic Of Photography</p>
                <p>Quality Shots</p>
                <p>Unique Stories</p>
            </div>
            <div class="about-card-text">Experience the magic of expert photography with LuminaVisions, where we focus on delivering quality and creativity in every shot. Our commitment is to provide you with stunning visuals that tell your unique story beautifully.</div>
            <div class="about-card-img">
                <img src="../assets/misty.jpg" />
            </div>
            <div class="about-card-logo"><h1>lumina<span>visions</span></h1></div>
        </div> 

    </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>


    </section>
    
    </template>
    
    <script>
    import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default defineComponent({
  name: 'CarouselCard',
  components: {
    
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
    },
  }),
})

    </script>
    
    
    <style>

.carousel__icon {
    stroke:rgb(0, 0, 0) !important;
    fill:rgb(0, 0, 0) !important;
}



.carousel__item {
  display: flex;
  justify-content: center; 
}
    .about-section {
    
    background-size:cover;
    background-position:center;
    display:flex;
    flex-direction:column;
    justify-content:center;
    }
    
  
    @media only screen and (max-width: 1024px) { 

      .about-card {

      width:360px !important;
      height:520px !important;
      display:flex;
      flex-direction:column;
      position:relative;
      border-radius:1px;
      margin:auto 12px auto 12px !important;
    }


    .about-card-header {
      width:100%;
      padding:12px;
      margin:auto;
      display:flex;
      justify-content:space-between;
      position:absolute;
      top:0;
      left:0;
      
    }
    
    .about-card-header p{
      max-width:20%;
      font-weight:600;
      font-size:8px !important;
      text-align:left;
      padding:4px;
      text-transform:uppercase;
    }
    
    .about-card-text {
    
      position:absolute;
      bottom:320px !important;
      left:0;
      font-size:15px;
      text-align:left;
      padding:12px;
    }
    
    .about-card-img {
    
    padding:12px;
    object-fit:cover;
    object-position:center;
    overflow:hidden;
    height:250px;
    position:absolute;
      bottom:80px !important;
      left:0;
    }
    
    .about-card-logo {
      width:100%;
      padding:12px;
      margin-bottom:10px !important;
      position:absolute;
      bottom:0;
      left:0;
    }

    .about-card-logo h1{
    margin:auto;
    font-size:36px !important; 
    font-weight:300;
    }

    .about-card-logo h1 span{
      font-weight:400;
    }









    }
  
    
    .about-card {
      background-color:rgba(255, 255, 255, 0.921);
      width:480px;
      height:580px;
      display:flex;
      flex-direction:column;
      position:relative;
      border-radius:1px;
      margin:auto 24px auto 24px;
    }
    
    .about-card-header {
      width:100%;
      padding:12px;
      margin:auto;
      display:flex;
      justify-content:space-between;
      position:absolute;
      top:0;
      left:0;
      
    }
    
    .about-card-header p{
      max-width:20%;
      font-weight:600;
      font-size:10px;
      text-align:left;
      padding:4px;
      text-transform:uppercase;
    }
    
    .about-card-text {
    
      position:absolute;
      bottom:370px;
      left:0;
      font-size:15px;
      text-align:left;
      padding:12px;
    }
    
    .about-card-img {
    
    padding:12px;
    object-fit:cover;
    object-position:center;
    overflow:hidden;
    height:250px;
    
    position:absolute;
      bottom:120px;
      left:0;
    }
    
    .about-card-logo {
      width:100%;
      padding:12px;
      margin-bottom:20px;
      position:absolute;
      bottom:0;
      left:0;
    }

    .about-card-logo h1{
    margin:auto;
    font-size:48px;
    font-weight:300;
    }

    .about-card-logo h1 span{
      font-weight:400;
    }
    


    @media only screen and (max-width: 1024px) {




    }

    </style>
    