<template>
    <div class="richtext-div" v-html="renderedHtml"></div>
  </template>
  
  <script>
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
  
  export default {
    props: {
      richText: {
        type: Object,
        required: true
      }
    },
    computed: {
      renderedHtml() {
        return documentToHtmlString(this.richText);
      }
    }
  }
  </script>
  
  <style >
    .richtext-div p {

        color:white;

    }
</style>