<template>

    <section class="contact-title-section">

      
          <h1 class="contact-title-h1 fade-in">Contact us</h1>
          <div class="contact-title-under">
            <div>
              <div class="contact-form-list">
              <h1>
                social media
              </h1>
              <ul>
                        <li><a href="instagram.com" target="_blank" rel="noreferrer noopener">instagram</a></li>
                        <li><a href="facebook.com" target="_blank" rel="noreferrer noopener">facebook</a></li>
                        <li><a href="threads.com" target="_blank" rel="noreferrer noopener">threads</a></li>
                    </ul>
            </div>
            
            </div>

            <div>
              <div class="contact-form-list">
              <h1>
                reach out
              </h1>
              <ul>
                        <li><a href="mail:info@luminavisions.com" target="_blank" rel="noreferrer noopener">info@luminavisions.com</a></li>
                        <li><a href="tel:+4988745894684" target="_blank" rel="noreferrer noopener">+4988745894684</a></li>
                        <li><a href="maps.google.com" target="_blank" rel="noreferrer noopener">40 E 7th St, New York, NY 10003, USA</a></li>
                    </ul>
            </div>
              
            </div>

            <div class="contact-title-lines">
              
              <h2></h2>
              <h3></h3>
              <h4></h4>
            </div>

      </div>

    </section>

<section class="contact-form-section">
    <div class="contact-form">

      <img class="contact-form-img" src="../assets/karl-solano-wE7iXvGMPhI-unsplash(1).jpg" />

      <form @submit.prevent="sendEmail">
        <div>
          <label for="name">Name:</label>
          <input v-model="form.name" type="text" id="name" required>
        </div>
        <div>
          <label for="email">Email:</label>
          <input v-model="form.email" type="email" id="email" required>
        </div>
        <div>
          <label for="message">Message:</label>
          <textarea v-model="form.message" id="message" required></textarea>
        </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
    <div class="right-side-contact">
      <img src="../assets/contact-text.png" class="contact-text-img" />
    </div>
  </section>
  </template>
  
  <script>

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

  export default {
    data() {
      return {
        form: {
          name: '',
          email: '',
          message: ''
        }
      };
    },
    methods: {
      async sendEmail() {
        
        try {
          const response = await fetch('https://devcraft.hu/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.form),
          });
  
          if (response.ok) {
            toast.success("Email sent successfully!");
            this.form = { name: '', email: '', message: '' }; // Reset form
          } else {
            toast.error("Failed to send email. Please try again later.");
          }
        } catch (error) {
          console.error('Error:', error);
        
          toast.error("An error occurred while sending the email.");
        }
      }
    }
  }
  </script>
  
  <style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.contact-title-lines {
  position:absolute;
  width:100%;
  bottom:0;
  left:0;
  display:flex;
  flex-direction: column;
  justify-content:space-between;
}
.contact-title-lines h1{
  width:70%;
  border-bottom:1px solid rgba(128, 128, 128, 0.252);
  
  padding-top:36px;
}
.contact-title-lines h2{
  width:80%;
  border-bottom:1px solid rgba(128, 128, 128, 0.252);
  padding-top:36px;
}
.contact-title-lines h3{
  width:90%;
  border-bottom:1px solid rgba(128, 128, 128, 0.252);
  padding-top:36px;
}
.contact-title-lines h4{
  width:100%;
  border-bottom:1px solid rgba(128, 128, 128, 0.252);
  padding-top:36px;
}

.contact-form-list ul li {

  transition:0.3s ease-in-out;

}
.contact-form-list ul li:hover{
color:grey;
transition:0.3s ease-in-out;

}

.contact-form-section {

height:100vh;
width:100%;
display:flex;
flex-direction:row;
background-image:url('../assets/background-2.jpg')
}  

.right-side-contact {
  width: 12vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  position: relative;

}

.contact-text-img {
  
  max-height: 80vh;

}

.contact-title-h1 {
    font-family: "Montserrat", sans-serif;
    position:absolute;
    bottom:80vh;
    left:5vh;
    font-size:72px;
    color:white;
    font-weight:300;
    text-transform:uppercase;  
  }

  .contact-title-under {
    position:absolute;
    bottom:0;
    left:0;
    background-color:rgb(15, 15, 15);
    width:80vw;
    height:60vh;
    border-top-right-radius: 2px;
    display:flex;
    flex-direction:row;
  }

  .contact-title-under div{
    color:white;
    margin: 48px auto 24px auto;
  }

  .contact-title-under div div h1{
    font-weight:300;
    font-size:32px;
}

.contact-title-under div div h2{

}
  .contact-form {
    margin: auto;
    font-weight: 800;
    display:flex;
    flex-direction:row;
    height:600px;
    background-color:rgba(228, 232, 232, 0.92);
    width:1000px;
  }
  .contact-form form{
   
    width:60%;
    padding:24px;
    margin:auto;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    
  }
  .contact-form form button{

      padding:12px;
      background-color:rgb(191, 191, 191);
      border-radius:0px;
      color:rgb(27, 27, 27);
      font-weight:300;
      text-transform: lowercase;
      font-size:16px;
      margin-top:24px;
      transition:0.3s;
 }
 .contact-form form button:hover{
  background-color:white;
  transition:0.3s;
}

  .contact-form form div{
   display:flex;
   flex-direction:column;
   justify-content:center;
   width:70%;
   padding:6px;
 }
 .contact-form form div label{
  color:rgb(27, 27, 27);
  font-weight:300;
  text-transform:lowercase;
  
 }
 .contact-form form div input{
  
  font-weight:400;

  border-radius:0px;
  background-color: rgb(191, 191, 191);
  border: 0px;
 }
 .contact-form form div textarea{
  
  font-weight:400;

  border-radius:0px;
  height:200px;
  border: 0px;
  background-color: rgb(191, 191, 191);
  overflow:scroll;
  resize: none;
 }

  .contact-form-img {
    height:600px;
    width:40%;
    object-fit:cover;
  }

  .contact-title-section {

    background-image:url('../assets/erik-torres-3hk51ckZM5A-unsplash(2).jpg');
    width:100%;
    height:140vh;
    background-size:cover;
    background-position:top;
    position:relative;
    top:0;
    left:0;
  }


  @media only screen and (max-width: 1024px) { 

    .contact-form-img {
    height:600px;
    width:10% !important;
    object-fit:cover;
  }


  .contact-form form div{
   display:flex;
   flex-direction:column;
   justify-content:center;
   width:100% !important; 
   padding:6px;
 }

 .contact-form {
    margin: auto;
    font-weight: 800;
    display:flex;
    flex-direction:row;
    height:600px;
    background-color:rgba(228, 232, 232, 0.92);
    width:100vw !important;
  }
  .contact-form form{
   
    width:100% !important;
    padding:24px;
    margin:auto;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    
  }
  .right-side-contact {
  display:none;
}

.contact-title-under div div h1{
    font-weight:300;
    font-size:32px;
}

.contact-title-h1 {
  bottom: auto !important;
  top:30vh !important;
  left: 1vh;
  font-size: calc(32px + 3vw);
  font-weight: 300;
  text-transform: uppercase;
}

.contact-title-under {
    position:absolute;
    bottom:0;
    left:0;
    background-color:rgb(15, 15, 15);
    width:90vw;
    height:80vh;
    border-top-right-radius: 2px;
    display:flex;
    flex-direction:column !important;
  }

  .contact-title-under div {
  color: white;
  margin: 12px auto 12px auto;
  width:100%;
  padding-left:12px;
}

   }

  </style>
  