<template>
<section class="references-main-page">



  <div class="references-page-container">
    <div class="references-page-box" v-for="(album, index) in albums" :key="index">
      
      <router-link :to="{ name: 'ReferencesDetails', params: { slug: album.albumSlug } }">
        <img class="references-page-img" :src="album.coverUrl" alt="Cover Image" />
      </router-link>

        <div class="references-page-text">
          <router-link :to="{ name: 'ReferencesDetails', params: { slug: album.albumSlug } }"><h1>{{ album.title }}</h1></router-link>
          <p>{{ album.shortDescription }}</p>
        </div>
      
    </div>
  </div>


</section>
</template>


      
<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import ReferencesDetails from '../components/ReferencesDetails.vue'

export default defineComponent({
  name: 'AlbumComponent',
  components: ReferencesDetails,
  data() {
    return {
      albums: []  
    };
  },
  created() {
    this.fetchAlbums(); 
  },
  methods: {
    fetchAlbums() {
      axios.get('https://devcraft.hu/proxy-server/api/albums/')
        .then(response => {
          this.albums = response.data.map(item => ({
            title: item.fields.title,
            shortDescription: item.fields.shortDescription,
            albumSlug: item.fields.albumSlug,
            coverUrl: item.fields.cover.fields.file.url
          }));
        })
        .catch(error => console.error('Error fetching albums:', error));
    }
  }
})
</script>
  
      
<style scoped>

@media only screen and (max-width: 1024px) {


  .references-page-box {
    height: 75vh !important;
    border-left: 12px solid white !important;
}

.references-page-text h1 {
  font-size: calc(24px + 1vw) !important;
  font-weight: 300;
  text-transform: uppercase;
  padding-left: 12px !important;
  margin:auto !important;
}

.references-page-text p {
  font-size: calc(12px + 0.5vw) !important;
  font-weight: 300;
  text-transform: uppercase;
  padding-left: 12px !important;
  padding-bottom: 12px !important;
  margin:auto !important;
}

.references-page-text h1 {
  
  padding-top:12px !important;

}
.references-page-text {
  color: rgb(41, 41, 41)  !important;
  background-color: rgba(255, 255, 255, 0.942)  !important;
  top:auto !important;
  bottom: 0 !important;
  left: 0;
  height:max-content !important;

}


 }


.references-main-page {
  background-color: var(--background-color);
}

.references-page-container {
  background-color: var(--background-color);
}

.references-page-box {
  height: 50vh;
  border-left: 36px solid var(--background-color);
  position: relative;
  transition: 0.8s ease-in-out;
  filter: grayscale(0.9);
  overflow: hidden; /* Ensures the pseudo-element does not exceed the box */
}

.references-page-box:hover {
  transition: 1s ease-in-out;
  border-left: 36px solid white;
  filter: grayscale(0.0);
}

.references-page-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.8s ease-in-out;
}

.references-page-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Make sure the text container covers the whole box */
  color: white;
  transition: color 0.8s ease-in-out; /* Transition for text color */
  z-index: 10; /* Make sure text is above the pseudo-element */
}

.references-page-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%; /* Start from the left */
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.942);
  transition: left 0.8s ease-in-out; /* Smooth transition for the background */
  z-index: -1; /* Behind the text content */
}

.references-page-box:hover .references-page-text::before {
  left: 0; /* Move the background to cover the text on hover */
}

.references-page-box:hover .references-page-text {
  color: rgb(41, 41, 41); /* Change text color on hover */

}

.references-page-text h1 {
  font-size: 84px;
  font-weight: 300;
  text-transform: uppercase;
  padding-left: 24px;
  padding-top: 0px;
}

.references-page-text p {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  padding-left: 24px;
}
</style>



      