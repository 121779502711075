// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomeComponent from '../components/HomeComponent.vue';
import AlbumComponent from '../components/AlbumComponent.vue';
import ContactForm from '../components/ContactForm.vue';
import ReferencesDetails from '../components/ReferencesDetails.vue'
const routes = [
  { path: '/', component: HomeComponent },
  { path: '/references', component: AlbumComponent },
  { path: '/contact', component: ContactForm },
  {
    path: '/references/:slug',
    name: 'ReferencesDetails',
    component: ReferencesDetails,
    props: true
  }
  

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
