<template>



<section class="home-main-section">

  <ParallaxComponent :imageUrl="imageUrl"  height="1700px" :parallaxSpeed="0.2">
  <section class="home-title-section">
    <div class="home-title-content">
        <div class="fade-overlay" :style="{ opacity: overlayOpacity }"></div>
        <h1 v-show="activeIndex === 0" class="fade-in flip-animation">lumina<span>visions</span></h1>
        <h1 v-show="activeIndex === 1" class="fade-in flip-animation">See the World Through Our Lens</h1>
        <h1 v-show="activeIndex === 2" class="fade-in flip-animation">Every Shot Tells a Story</h1>
      
    </div>
    <section class="purpose-section">
      <div>
        <h1>
          Who we are?
        </h1>
        <p>
          We are a dynamic team of passionate photographers with a keen eye for capturing life's most significant moments. 
        </p>
      </div>
      <div>
      <h1>
        Our Purpose
      </h1>
      <p>
        To immortalize the fleeting moments of today for future generations to cherish. We strive to capture the essence of each subject, crafting compelling imagery that resonates and inspires.
      </p>
      </div>
  </section>
  <section class="lines-section">
    <h1></h1>
    <h2></h2>
    <h3></h3>
    <h4></h4>
  </section>
</section>
</ParallaxComponent>

<section class="references-promo-section">

<div class="references-promo-title">

  <h1>
    Take a look at our work
  </h1>
  <p>
    Dive into our curated gallery showcasing a diverse range of photography from vibrant street captures to serene landscapes. Each image is crafted with precision, reflecting our commitment to artistry and attention to detail. Whether it’s the emotion of a candid moment or the majestic stillness of nature, our portfolio speaks to the passion and skill we bring to every shot. Explore our work to see how we turn ordinary scenes into extraordinary memories.
  </p>
</div>
<div class="references-bigpictures">
  <ReferencesShowcaseComponent />
</div>
</section>


<section class="about-main-section">
  <div class="about-left-section"><h1>Story</h1><CarouselCard class="carousel-pos" /></div>
  <div class="about-right-section"><h2>About</h2>
    <h3>The founder of LuminaVisions discovered their passion for photography during a transformative year abroad, capturing the subtle interplay of light and landscape. This initial spark turned into a lifelong pursuit to capture moments that speak universally, blending a spirit of adventure with a deep connection to storytelling in every frame.</h3>
    <div>
    <p class="p4">PhotographyPassion</p>
    <p class="p1">Creative Visionary</p>
    <p class="p2">Founder Story</p>
    <p class="p3">Adventure In EveryShot</p>
  </div>
</div>
</section>

</section>
<FooterComponent />
</template>

<script>
import CarouselCard from './CarouselCard.vue';
import FooterComponent from './FooterComponent.vue'
import ReferencesShowcaseComponent from './ReferencesShowcaseComponent.vue'
import ParallaxComponent from './ParallaxComponent.vue'
import backgroundImage from '../assets/yohann-libot-atBSCnuYyRI-unsplash.jpg';


export default {
  name: 'HomeComponent',
  components: {
    CarouselCard,
    FooterComponent,
    ReferencesShowcaseComponent,
    ParallaxComponent,
  },
  data() {
    return {
      activeIndex: 0, // Ensure this is defined
      isLoading: true,
      scrollY: 0,
      maxScroll: 900,
      imageUrl: backgroundImage
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.interval = setInterval(this.nextTitle, 4000); // Set interval and store reference
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.interval); // Clear the interval
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },
    nextTitle() {
      this.activeIndex = (this.activeIndex + 1) % 3; // Cycle through 0, 1, 2
    }
  },
  computed: {
    overlayOpacity() {
      return Math.min(this.scrollY / this.maxScroll, 1);
    }
  }
}
</script>




<style scoped> 

@media only screen and (max-width: 1024px) {
 



}


.flip-animation {
  animation: slideDownFadeIn 0.6s both;
}

@keyframes slideDownFadeIn {
  from {
    transform: translateY(-20px); /* Start above the final position */
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in {
  transition: opacity 0.75s ease-in-out;
}

.home-title-content {
  width: 100vw;
  height: 1700px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index:2;
}



.fade-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  transition: opacity 0.5s ease;
}

.home-title-content h1 {
  font-weight: 500;
  color: white;
  font-size: 32px;
  letter-spacing: 1px;
  position: absolute;
  top: 50vh;
  z-index: 10; /* Ensures text stays above the overlay */
  text-transform:lowercase;
}


.home-title-content h1 span {
  font-weight: 700;
  color: white;
  font-size: 32px;
  letter-spacing: 1px;
}

.lines-section {
position:absolute;
right:48px;
top:90vh;
display:flex;
flex-direction:row;
height:90vh;
justify-content:space-between;
width:20vh;
z-index:3;
}

.lines-section h1{
  border-right:1px solid rgba(128, 128, 128, 0.252);
  height:70%;
 
}

.lines-section h2{
  border-right:1px solid rgba(128, 128, 128, 0.252);
  height:80%;
  
}

.lines-section h3{
  border-right:1px solid rgba(128, 128, 128, 0.252);
  height:90%;
  
}
.lines-section h4{
  border-right:1px solid rgba(128, 128, 128, 0.252);
  height:100%;
  
}
.purpose-section {

  position:absolute;
  top:100vh;
  color:var(--font-color);
  z-index:4;
}



.purpose-section div {
  display:flex;
  max-width:80vw;
  margin-left:24px;
  background-color:var(--secondary-background);
  padding:12px;
  padding-bottom:36px;
  margin-bottom:12px;
}

.purpose-section div h1{
  width:20%;
  text-transform:uppercase;
  padding:12px;
  font-size:20px;
}

.purpose-section div p{
  width:80%;
  font-size:40px;
}

@media only screen and (max-width: 1024px) {
 
.purpose-section {
position:absolute;
top:100vh;
color:var(--font-color);
z-index:4;
}

  .purpose-section div {
  display:flex;
  max-width:90vw;
  margin-left:12px;
  background-color:var(--secondary-background);
  padding:12px;
  padding-bottom:24px;
  margin-bottom:36px;
  flex-direction:column;
}

.purpose-section div h1{
  width:100%;
  text-transform:uppercase;
  padding:12px;
  font-size:calc(12px + 0.5vw);
}

.purpose-section div p{
  width:100%;
  font-size:calc(20px + 0.5vw);
}


.references-promo-section {
  background-color: var(--background-color);
  padding:12px !important;
}

.references-promo-title h1{
color:var(--font-color);
font-size:36px !important;
text-transform:uppercase;
font-weight:300;
padding-left:0px !important;
}
  
.references-promo-title p{
padding:12px;
padding-left:12px !important;
color:var(--font-color);
font-size:16px;
font-weight:300;
}

}

.references-promo-section {
  background-color: var(--background-color);
  padding:36px;
}

.references-promo-title h1{
color:var(--font-color);
font-size:48px;
text-transform:uppercase;
font-weight:300;
padding-left:36px;
}

.references-promo-title p{
padding-left:36px;
color:var(--font-color);
font-size:16px;
font-weight:300;
}


@media only screen and (max-width: 1024px) {

  .about-main-section {
  height:100% !important;
  display:flex !important;
  flex-direction:column !important;
  position:static !important;
  width:100vw !important;
}

.about-left-section {
width:100% !important;
height:100vh !important;
position:relative !important;
top:auto !important;
left:auto !important;
}

.about-left-section h1{
  font-weight:200;
  font-size:40px !important;
  padding:12px;

}


.carousel-pos {
position:absolute !important;
top:50% !important;
left:auto !important;
transform: translateY(-50%);
z-index:99;
width:100%;
}

.about-right-section{
  background-color:rgba(255, 255, 255, 0.921);
  width:100% !important;
  height:auto !important;
  position:static !important;
  top:auto !important;
  left:auto !important;
  padding-bottom:12px;
}

.about-right-section h2{
  
  text-transform:uppercase;
  font-weight:300;
  font-size:40px !important;
  color:rgb(31, 31, 31);
  padding:12px;

}



.about-right-section h3{
  padding:12px;
  font-size:14px;
  font-weight:300;
  padding-bottom:36px;
}
.about-right-section div p{
  margin:10px auto 5px 12px;
  padding:6px;
  text-align:center;
  width:max-content;
  text-transform:uppercase;
  font-weight:200;
}

.p1 {
border:1px solid rgb(31, 31, 31);
border-radius:25px;
}

.p2 {
  border:1px solid rgb(31, 31, 31);
border-radius:2px;
}

.p3 {
  border:1px solid rgb(31, 31, 31);
border-radius:2px;
}

.p4 {
  border:1px solid rgb(31, 31, 31);
border-radius:2px;
}

.carousel-title {

  font-weight:300;
  font-size:50px !important;
  color:white;

}

}

.about-main-section {
  background-image:url('../assets/bback.jpg');
  background-size:cover;
  height:100vh;
  display:flex;
  position:relative;
}


.about-left-section {
width:80%;
height:100vh;
position:relative;
top:0;
left:0;
}

.about-left-section h1{
  font-weight:200;
  font-size:90px;
  color:rgb(94, 94, 94);
  padding:12px;
  text-transform: uppercase;
}

.carousel-pos {
position:absolute;
margin: 0;
left:0;
top: 50%;
transform: translateY(-50%);
z-index:99;
width:100%;
}

.about-right-section{
  background-color:rgba(255, 255, 255, 0.921);
  width:20%;
  height:100vh;
  position:absolute;
  top:0;
  right:0;
}

.about-right-section h2{
  
  text-transform:uppercase;
  font-weight:300;
  font-size:80px;
  color:rgb(31, 31, 31);
  padding:12px;
}



.about-right-section h3{

  padding:12px;
  font-size:14px;
  font-weight:300;
  padding-bottom:36px;
}
.about-right-section div p{
  margin:10px auto 5px 12px;
  padding:6px;
  text-align:center;
  width:max-content;
  text-transform:uppercase;
  font-weight:200;
}

.p1 {
border:1px solid rgb(31, 31, 31);
border-radius:25px;
}

.p2 {
  border:1px solid rgb(31, 31, 31);
border-radius:2px;
}

.p3 {
  border:1px solid rgb(31, 31, 31);
border-radius:2px;
}

.p4 {
  border:1px solid rgb(31, 31, 31);
border-radius:2px;
}

.carousel-title {

  font-weight:300;
  font-size:70px;
  color:white;

}

</style>
